import { LatLng } from "./__generated__/proto/google/type/latlng"

export const isDev = process.env.NODE_ENV === "development"
export const isProd = process.env.NODE_ENV === "production"
export const isCI = process.env.CI === "true"
export const isGatsbyCloud = process.env.GATSBY_CLOUD === "true"
export const SITE_URL = "https://parkings.i-tech-corp.co.jp"
export const PKSHA_GRPC_API_ADDRESS = isProd
  ? "api.i-tech-corp.dev:443"
  : "api.dev.i-tech-corp.dev:443"
export const PKSHA_HTTP_API_URL = isProd
  ? "https://japi.i-tech-corp.dev"
  : "https://japi.dev.i-tech-corp.dev"
export const LEGACY_API_BASE_URL = isProd
  ? "https://www.itcbb.net"
  : "https://www.itcbb-dev.com"
export const QTNET_BASE_URL = isProd
  ? "https://qt-net.itcbb.net"
  : "https://qt-net.itcbb-dev.com"
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const PARKINGS_COUNT_PER_PAGE = 20
export const RESPONSIVE_BREAKPOINT = 640
export const DEFAULT_MAP_CENTER: LatLng = {
  latitude: 35.6812362,
  longitude: 139.7627474,
} // 東京駅
export const DEFAULT_MAP_ZOOM = 16
export const QT_NET_APP_IOS_URL =
  "https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
export const QT_NET_APP_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool"

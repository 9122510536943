import "remixicon/fonts/remixicon.css"
import { Link, navigate } from "gatsby"
import { useAtom } from "jotai"
import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  Dispatch,
} from "react"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { parkingNameAtom } from "../../atoms"
import * as config from "../../config"
import { User } from "../../domain/models"
import { useAuth } from "../../middleware/AuthGuard"
import { Button } from "./button"
import * as styles from "./header.module.scss"

interface HeaderProps {
  currentParking?: Parking
}

export const Header = ({ currentParking }: HeaderProps): ReactElement => {
  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })
  // MARK: - States
  const [, setParkingName] = useAtom(parkingNameAtom)
  const [isScrolled, setIsScrolled] = useState(false)
  const { user, logout } = useAuth()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const scrollThreshold = isMobile ? 100 : 170
        setIsScrolled(window.scrollY > scrollThreshold)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [isMobile])

  const handleReservationClick = useCallback(async () => {
    if (!user) {
      setParkingName(currentParking?.name || null)
      await navigate("/signin")
    } else {
      await navigate(`/${currentParking?.name}/reservation`)
    }
  }, [currentParking, user])

  return (
    <header className={styles.header}>
      <div className={styles.row}>
        <div className={styles.serviceName}>
          <Link to="/">
            <img
              className={styles.logo}
              src="/QT-net.webp"
              alt="logo"
              width={isMobile ? 48 : 77}
              height={isMobile ? 20 : 32}
            />
            <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
              <p className={styles.title}>駐車場検索</p>
            </MediaQuery>
          </Link>
        </div>
        <div className={styles.menu}>
          {isScrolled && currentParking?.reservable ? (
            <ReservationMenu onClick={handleReservationClick} />
          ) : user ? (
            <UserMenu
              user={user}
              onReservationStatusClick={async () =>
                await navigate("/reservation-detail")
              }
              onLogoutClick={logout}
            />
          ) : (
            <DefaultMenu />
          )}
        </div>
      </div>
    </header>
  )
}

const DefaultMenu = (): ReactElement => {
  return (
    <>
      {typeof window !== "undefined" &&
      window.location?.pathname === "/signin" ? (
        <Link to="/" className={styles.menuItem}>
          トップへもどる
        </Link>
      ) : (
        <>
          <Link to="/signin" className={styles.menuItem}>
            ログイン
          </Link>
          <Link
            to={`${config.QTNET_BASE_URL}/signup`}
            className={styles.menuItem}
          >
            新規登録
          </Link>
        </>
      )}
    </>
  )
}

const ReservationMenu = ({
  onClick,
}: {
  onClick: Dispatch<void>
}): ReactElement => {
  return (
    <Button shape="rounded" onClick={() => onClick()}>
      予約手続きへ
    </Button>
  )
}

const UserMenu = ({
  user,
  onReservationStatusClick,
  onLogoutClick,
}: {
  user: User
  onReservationStatusClick: Dispatch<void>
  onLogoutClick: Dispatch<void>
}): ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isDropdownOpen &&
      !event.composedPath().some(el => (el as HTMLElement).id === "userSection")
    ) {
      setIsDropdownOpen(false)
    }
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isDropdownOpen])

  return (
    <div id="userSection" className={styles.userSection}>
      <div className={styles.userInfo} onClick={toggleDropdown}>
        <p>{user.name}様</p>
        <i className="ri-arrow-down-s-line"></i>
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdownMenu}>
          <button
            onClick={() => {
              setIsDropdownOpen(false)
              onReservationStatusClick()
            }}
            className={styles.dropdownItem}
          >
            予約ステータス
          </button>
          <button
            onClick={() => {
              setIsDropdownOpen(false)
              onLogoutClick()
            }}
            className={styles.dropdownItem}
          >
            ログアウト
          </button>
        </div>
      )}
    </div>
  )
}
